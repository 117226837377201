const search4i = function () {
     const popularSearchCount = 10,
          autoCompleteResultAmount = 8,
          searchSuggestAmount = 7,
          timeoutInterval = 400,
          minCharacters = 3;

     let searchBox, searchButton, searchDropdown, searchStatus, searchDropdownClose, searchOpenSmall, headerSection, closeSearchMobile, searchTimeout, topSearches;
     let popularSearches = {}, recentSearches = {}, relatedSearches = {}, deleteError = false;
     let autoCompleteAbort = new AbortController(), suggestionAbort = new AbortController();

     const init = async function () {
          [searchBox, searchButton, searchDropdown, searchStatus, searchDropdownClose, searchOpenSmall, headerSection, closeSearchMobile] = await GetSearchElementsAsync();

          // these are basically static we can grab them once on page load
          [recentSearches, relatedSearches, popularSearches] = await FetchRecentRelatedPopularAsync();
          //[recentSearches, relatedSearches, popularSearches] = await MockFetchRecentRelatedPopularAsync();

          // open the dropdown if user focuses in the search box 
          headerSection.addEventListener("focusin", async (e) => {
               // if we're focusing the search text input and the dropdown is hidden
               if (e.target === searchBox && searchDropdown.classList.contains("hide")) {
                    // delay otherwise element not being there throws     
                    searchTimeout = setTimeout(async () => {
                         searchDropdown.innerHTML = await ShowSearchDropDown();
                         searchShown();
                    }, timeoutInterval);
               }
          });

          // not document, hitting enter on document is triggering this when in menu
          headerSection.addEventListener("keydown", searchKeys);
          
          searchButton.addEventListener("click", async (e) => {
          	 	e.preventDefault();
                    await Search(searchBox.value.trim());
                    //await MockSearch(searchBox.value.trim());
          });
          
          // search icon on small clicked -- only visible on mobile
          searchOpenSmall.addEventListener("click", async (e) => {
                if (headerSection.classList.contains("hideOnSmall")) {
                     headerSection.classList.remove("hideOnSmall");
                     searchDropdown.innerHTML = await ShowSearchDropDown();
                     searchShown();
                     closeSearchMobile.focus();
                } else {
                     headerSection.classList.add("hideOnSmall");
                     closeSearch();
                     searchOpenSmall.focus();
                }
          });
          
          async function searchClicks(e) {
		 if (e.target === closeSearchMobile) {
                    headerSection.classList.add("hideOnSmall");
		 	closeSearch();
                    searchOpenSmall.focus();
               }

               // search term or search term arrow icon clicked
               if (e.target.classList.contains("rsSearch") ||
                    e.target.classList.contains("rsRelatedSearch") ||
                    e.target.classList.contains("psTerm") ||
                    e.target.classList.contains("goSearch")) {
                    e.preventDefault();

                    await PopulateSearchBox(e.target.getAttribute('data-val'));
               }

               // search term clicked in autocomplete list
               if (e.target.classList.contains("acSearch")) {
                    e.preventDefault();
                    await Search(e.target.getAttribute('data-val'));
                    //await MockSearch(e.target.getAttribute('data-val'));
               }

               // anywhere outside of the search elements clicked hide search dropdown
               if (!e.target.classList.contains("searchitem") &&
                    e.target !== searchBox &&
                    e.target !== searchButton &&
                    e.target !== searchDropdown &&
                    e.target !== searchOpenSmall) {
                    closeSearch();
               }

               if (e.target.classList.contains("removeRecent")) {
                    e.preventDefault();
                    const idx = e.target.id.split("_")[1];
                    const term = e.target.getAttribute('data-val');
                    await DeleteRecentSearch(idx, term, false);
               }

               if (e.target.id === "clearRecentSearches") {
                    e.preventDefault();
                    await DeleteRecentSearch(0, '', true);
               }
          }
          // Concurrently grab all the elements used in search as an array of promises
          async function GetSearchElementsAsync() {
               return await Promise.all([
                    document.getElementById("txtSearch"),
                    document.getElementById("submitSearch"),
                    document.getElementById("searchDropdown"),
                    document.getElementById("searchStatus"),
                    document.getElementById("closeSearchDropdown"),
                    document.getElementById("searchOpenSmall"),
                    document.querySelector(".headerSearchBar"),
                    document.getElementById("closeSearch")
               ]);
          }
          async function searchKeys (e) {
          	// search box keyup
               if (searchTimeout) clearTimeout(searchTimeout);

               // pause while user types
               searchTimeout = setTimeout(async () => {
                    // does the array of keys not include what was pressed / a character or number
                    if (e.target === searchBox && !["Enter", "Escape", "ArrowDown", " "].includes(e.key)) {
                         searchDropdown.innerHTML = await ShowSearchDropDown();
                         searchShown();
                    }
               }, timeoutInterval);

               //
               if (e.key === "Enter") {
                    e.preventDefault();
                    // get which element is tabbed or focused on
                    const focusedElement = document.activeElement;
                    // enter searches -- not using keyboard navigation
                    if (!focusedElement.matches('.rsSearch, .rsRelatedSearch, .psTerm, .goSearch, .removeRecent, .acSearch')) {
                         await Search(searchBox.value.trim());
                         //await MockSearch(searchBox.value.trim());
                    } else {
                         focusedElement.click();
                    }
               }

               // escape key closes dropdown
               if (e.key === "Escape") {
                    e.preventDefault();
                    if (!searchDropdown.classList.contains("hide")) {
                        closeSearch();
                         //searchBox.focus(); should we focus on this ? it will reopon the dropdown
                    }
               }

               if (e.target === searchBox && e.key === "ArrowDown" && !searchDropdown.classList.contains("hide")) {
                    e.preventDefault();
                    searchDropdown.querySelector("button").focus();
               }
          }
          // Returns a promise of the recent, related, and popular searches
          async function FetchRecentRelatedPopularAsync() {
          	// First API call that returns a JSON object with two arrays: recentSearches and relatedSearches
          	let fetchRecentAndRelated = [];
          	let responseJSON;
		try{
			const response = await fetch("/Header/RecentSearches", {
				method: 'POST', 
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
			});
			if(!response.ok) throw new Error(`Error processing recent searches request: ${response.statusText}`);
			responseJSON = await response.json();
		}catch(error){
			console.error(error);
		}
		// the JSON object has two keys being returned from headercontroller: recentSearches and relatedSearches
		if(responseJSON) fetchRecentAndRelated = [responseJSON.recentSearches, responseJSON.relatedSearches];

               // Popular searches needs an amount parameter
               const data = { Term: '', Amount: popularSearchCount }; // Search Object

               // Second API call that returns a single array of popularSearches
          	let recentResponseJSON;
          	let fetchPopularSearches = "";
		try{
			const response = await fetch('/Header/PopularSearches', {
				method: 'POST', 
				body: JSON.stringify(data),
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
			});
			if(!response.ok) throw new Error(`Error processing popular searches request: ${response.statusText}`);
			recentResponseJSON = await response.json();
		}catch(error){
			console.error(error);
		}
               
               if(recentResponseJSON) fetchPopularSearches = recentResponseJSON;
              
               // Use Promise.all to wait for both API calls to complete
               const results = await Promise.all([fetchRecentAndRelated, fetchPopularSearches]);

               // Flatten the array of results to match the expected structure: [recentSearches, relatedSearches, popularSearches]
               // ... means all of the elements in the array of results[0], which is an array of 2 arrays in this case
               return [...results[0], results[1]];

          }
          async function FetchSearchSuggestions(searchText, amount = autoCompleteResultAmount) {

               const data = { Term: searchText, Amount: amount }; // Search Object

               // Abort any previous requests
               suggestionAbort.abort();
               suggestionAbort = new AbortController(); // Create a new controller for the new request

               try {
                    const response = await fetch('/Header/SearchBoxSuggestions', {
                         method: 'POST',
                         body: JSON.stringify(data),
                         headers: {
                              'Content-Type': 'application/json',
                              'X-Requested-With': 'fetch'
                         },
                         signal: suggestionAbort.signal
                    });

                   // if (!response.ok)  throw new Error('Network response was not ok');

                    const responseText = await response.text();
                    topSearches = responseText.length ? responseText : "";
                    return topSearches;

               } catch (e) {
                    if (e.name !== 'AbortError') { // Don't do anything if the fetch was aborted
                         console.error('Failed to fetch search box suggestions:', e);
                         HideLoading(searchBox); // Assuming SearchBox.HideLoading is a function you've defined elsewhere
                    }
                    return ""; // Return empty string as a fallback in case of error
               }
          }
          async function FetchAutoComplete(searchText, amount = autoCompleteResultAmount) {
               const data = { Term: searchText.toLowerCase(), Amount: amount };

               // Abort any previous requests
               autoCompleteAbort.abort();
               autoCompleteAbort = new AbortController(); // Create a new controller for the new request

               try {
                    const response = await fetch('/Header/SearchAutoComplete', {
                         method: 'POST',
                         body: JSON.stringify(data),
                         headers: {
                              'Content-Type': 'application/json',
                              'X-Requested-With': 'fetch'
                         },
                         signal: autoCompleteAbort.signal
                    });

                    //if (!response.ok) throw new Error('Network response was not ok');

                    //when promise is resolved, return the response as json
                    const responseData = await response.json();
                    return responseData;
               } catch (e) {
                    if (e.name !== 'AbortError') { // Don't do anything if the fetch was aborted
                         console.error('Failed to fetch autocomplete suggestions:', e);
                         HideLoading(searchBox);
                    }
               }
          }
          function GetRecentSearchesAsync() {
               const createRecentSearch = [
                    "<div class='displayFlex flexSpaceBetween flexAlignItemsCenter'>",
                    "<h2 id='recentSearchHdr' class='text16 textSemiBold marginLeft20 marginTop5'>Your Recent Searches</h2>"];
               if (recentSearches && recentSearches.length > 0) {
                    createRecentSearch.push("<button type='button' id='clearRecentSearches' class='padding10 btnToHyp underline text16 searchitem marginLeft10 marginRight5'>clear recent<span class='visually-hidden searchitem'> search terms</span></button>");
               }
               createRecentSearch.push(" </div>");

               if (!recentSearches || !recentSearches.length > 0) {
                    createRecentSearch.push("<p class='margin10 marginLeft20'>No Recent Searches</p>");
               } else {
                    createRecentSearch.push("<ul id='recentSearchList' class='rsTermList text16 reset resetList borderBottomBlue' aria-labelledby='recentSearchHdr'>");
                    for (let i = 0; i < recentSearches.length; i++) {
                         createRecentSearch.push(
                              `<li class="rsTerm text16 displayFlex flexSpaceBetween flexAlignItemsCenter flexNoWrap searchitem marginLeft5">`,
                              `<button type="button" class="rsSearch btnToHyp text16 displayFlex flexAlignItemsCenter flexNoWrap fullWidth padding10 searchitem" data-val="${recentSearches[i]}">`,
                              `<svg class="textDarkestGray marginRight10" height="28px" width="28px" role="img" focusable="false" aria-hidden="true"> <use xlink:href="#svgIcon-searchHistory"></use> </svg>`,
                              `<span class="underline">${recentSearches[i]}<span class="visually-hidden"> search suggestions</span></span>`,
                              `</button>`,
                              `<button type="button" data-val="${recentSearches[i]}" id="recentSearch_${i}" class="removeRecent padding10 btnToHyp noLine darkestLink searchitem marginRight5">`,
                              `<svg class="show" height="20px" width="20px" focusable="false" aria-label="Remove Recent Search ${recentSearches[i]}"><title>Remove Recent Search ${recentSearches[i]}</title>`,
                              `<use xlink:href="#svgIcon-remove"></use></svg>`,
                              `</button></li>`
                         );
                    }
                    createRecentSearch.push("</ul>");
               }

               return createRecentSearch.join("");
          }
          function GetRelatedSearchesAsync() {
               if (relatedSearches && relatedSearches.length > 1) {
                    // base list structure
                    let createRelatedSearch = [
                         '<div class="recentSearchTerms padding10 bkgdLtGray">',
                         '<h2 id="relateRecent" class="text16 textSemiBold marginLeft15 marginBtm5">Related to Your Recent Searches</h2>',
                         '<ul class="reset resetList displayFlex flexNoWrap flexAlignItemsCenter" aria-labelledby="relateRecent">'
                    ];

                    // loop through results and create list items
                    for (let i = 0; i < relatedSearches.length; i++) {
                         createRelatedSearch.push(
                              `<li class="searchitem">`,
                              `<button type="button" class="rsRelatedSearch text16 btnToHyp margin5" data-val="${relatedSearches[i]}"><span class="show borderDkGray bkgdWhite roundCorners3 padding10">${relatedSearches[i]}<span class="visually-hidden"> search suggestions</span></span></button>`,
                              `</li>`
                         );
                    }

                    createRelatedSearch.push("</ul></div>");

                    // close and return list of related search results
                    return createRelatedSearch.join("");
               } else {
                    // no recent searches, wont be any related searches
                    return "";
               }
          }
          function GetPopularSearchesAsync() {
               const createPopularSearch = [
                    "<div class='popularSearchTerms padding10 bkgdLtGray'>",
                    "<h2 id='popularSearchHeader' class='text16 textSemiBold marginLeft10 marginBtm5'>Popular Search Terms</h2>"
               ];
		  if(popularSearches && popularSearches.results){
		  	createPopularSearch.push("<ul class='reset resetList displayFlex flexNoWrap flexAlignItemsCenter' aria-labelledby='popSearchHdr'>");
	               for (let i = 0; i < popularSearches.results.length; i++) {
	                    createPopularSearch.push(
	                         `<li class="searchitem"><button type="button" class="psTerm text16 btnToHyp margin5 searchitem" data-val="${popularSearches.results[i].searchTerm}"><span class="show bkgdWhite borderDkGray roundCorners3 padding10">${popularSearches.results[i].searchTerm}<span class="visually-hidden"> search suggestions</span><span></button></li>`
	                    );
	               }
	             		createPopularSearch.push("</ul>");
               }else{
               	createPopularSearch.push("<p>No popular search terms found.</p>");
               }

               createPopularSearch.push("</div>");

               return createPopularSearch.join("");
          }
          //Search Suggestions are returned as view components from controller
          async function GetSuggestionSearchesAsync(searchText) {

               return await FetchSearchSuggestions(searchText, searchSuggestAmount);
               //return await MockFetchSearchSuggestions(searchText, searchSuggestAmount);

          }
          async function GetAutoCompleteSuggestionsAsync(searchText) {
               const term = searchText.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); // term searched for in input field
               const re = new RegExp("(" + term.split(' ').join('|') + ")", "gi");

               // base element structure
               let createAutoComplete = ['<div class="searchSuggest">'];

               // fetch the autocomplete results
               let autoCompleteResults = await FetchAutoComplete(searchText, searchSuggestAmount);
               //let autoCompleteResults = await MockFetchAutoComplete(searchText, searchSuggestAmount);

               // has the FetchAutoComplete fufilled it's promise?
               if (autoCompleteResults === undefined) {
                    //try again
                    autoCompleteResults = await FetchAutoComplete(searchText, searchSuggestAmount);

                    // if still no results, return empty string
                    if (autoCompleteResults === undefined || autoCompleteResults.length < 1) {
                         return "";
                    }
               }

               // autocomplete list 
               createAutoComplete.push(`<ul class='acSuggestionList bkgdWhite text16 reset resetList animate animateFadeIn animateFaster borderBottomBlue' aria-label='Autocomplete list for ${searchText}'>`);

               // loop through results and create list items
               for (let i = 0; i < autoCompleteResults.results.length; i++) {
                    createAutoComplete.push("<li class='acSuggestion padding5 displayFlex flexNoWrap flexSpaceBetween flexAlignItemsCenter searchitem'>");
                    createAutoComplete.push(`<button type='button' class='acSearch btnToHyp underline text16 textLeft padding10 fullWidth' data-val='${autoCompleteResults.results[i].searchTerm}'>`);
                    createAutoComplete.push(`<span class='acSearch' data-val='${autoCompleteResults.results[i].searchTerm}'>${autoCompleteResults.results[i].searchTerm.replace(re, '<span class="textSemiBold">$1</span>')}</span><span class='searchResultNo'> (${autoCompleteResults.results[i].resultCount}<span class='visually-hidden'> products</span>)</span>`);
                    createAutoComplete.push("</button>");
                    createAutoComplete.push(`<button class='goSearch btnToHyp darkestLink noLine padding10 searchitem' aria-expanded='false' data-val='${autoCompleteResults.results[i].searchTerm}' aria-label='See Search Suggestions for ${autoCompleteResults.results[i].searchTerm}'>`);
                    createAutoComplete.push("<svg class='show' height='20px' width='20px' focusable='false' aria-hidden='true'><use xlink:href='#svgIcon-searchArrow'></use></svg>");
                    createAutoComplete.push("</button>");
                    createAutoComplete.push("</li>");
               }

               // close and return list of suggestion search results
               createAutoComplete.push("</ul>");
               return createAutoComplete.join('');
          }

          async function AddError(errorMessage) {
               RemoveError();
               const searchError = `<p id="searchError" class="text16 textDkRed textSemiBold padding10 paddingLeft20" role="alert" aria-atomic="true">${errorMessage}</p>`;
		 
		  //fix for noibu error
		  searchDropdown = document.getElementById("searchDropdown");
               // if the dropdown is hidden, show it or error is in the ether
               if (searchDropdown){
               	if(!searchDropdown.classList.contains("hide")) await ShowSearchDropDown();
	               // add the error message
	               if(searchDropdown && searchDropdown.querySelector("div")) searchDropdown.querySelector("div").insertAdjacentHTML("afterbegin", searchError);
               }
          }
          async function RemoveError() {
               if (document.getElementById("searchError")) document.getElementById("searchError").remove();
          }
          /*function ShowLoading(element) {
               element.insertAdjacentHTML("afterbegin", "<div id='searchLoading' class='spinner bkgdWhite borderDkGray paddingTop5 paddingBtm5 " +
                    "role='alert' aria-busy='true' aria-atomic='true' aria-label='Loading search autocomplete list, please wait'>" +
                    "<div class='bounce1'></div><div class='bounce2'></div><div class='bounce3'></div></div>");
          }*/
          function HideLoading() {
               if (document.getElementById("searchLoading")) document.getElementById("searchLoading").remove();
          }
          function AddKeyClose() {
               const dropDownBtns = document.querySelector(".searchDropDown").querySelectorAll("button");
               if(dropDownBtns.length > 0){
	               const lastBtn = dropDownBtns[dropDownBtns.length - 1];
	               const firstBtn = dropDownBtns[0];
	               //const userLinksFirstBtn = document.getElementById("userSpecificLinksBox").querySelector("button");
	
	               //when first button in the search drop down is shift+tab off of
	               // close the dropdown
	               firstBtn.addEventListener("keydown", async (e) => {
	                    if (e.key === "Shift" && e.key === "Tab") {
	                         //e.preventDefault();
	                         e.stopPropagation();
	                        closeSearch();
	                    }
	               });
	               //when last button in the search drop down is tabbed off of
	               //close the drop down
	               lastBtn.addEventListener("keydown", async (e) => {
	                    if (e.key === "Tab") {
	                         //e.preventDefault();
	                         e.stopPropagation();
	                         closeSearch();
	                         //TODO if it's the mobile header, add hideOnSmall class
	                         //headerSection.classList.add("hideOnSmall");
	                         //searchDropdown.innerHTML = "";
	                         //searchOpenSmall.focus();
	
	                         //userLinksFirstBtn.focus();
	                    }
	               });
	         }
          }
          
          async function searchShown(){
      	       AddKeyClose();
      		document.removeEventListener("click", searchClicks);
      		document.addEventListener("click", searchClicks);
          }
          
          async function closeSearch(){
          	document.removeEventListener("click", searchClicks);
          	searchBox.value = "";
          	searchDropdown.innerHTML = "";
          	 searchDropdown.classList.add("hide");
          	 searchDropdown.setAttribute("aria-hidden", "true");
          	 searchOpenSmall.setAttribute("aria-expanded", "false");
          }
          
          async function PopulateSearchBox(searchText) {
               // if it's the same word, do nothing
               if(searchBox.value !== searchText){
                    searchBox.value = searchText;
                    searchDropdown.innerHTML = await ShowSearchDropDown();
			searchShown();
               }
               //todo: add error to inform user that word is already in search box?
              
          }
          async function InsertRecentSearch(term) {
               const data = { Term: term, Amount: 0 }; // search object
               
               try {
                    const response = await fetch('/Header/InsertRecentSearch', {
                         method: 'POST',
                         body: JSON.stringify(data),
                         headers: {
                              'Content-Type': 'application/json',
                              'X-Requested-With': 'fetch'
                         }
                    });
               } catch (e) {
                    deleteError = true;
                    console.error("Error inserting the recent search:", e); // Log the error
               }
          }
          async function DeleteRecentSearch(idx, term, clearAll) {
               const data = { Index: idx, Term: term, ClearAll: clearAll }; // RecentSearch object
               try {
                    const response = await fetch('/Header/DeleteRecentSearch', {
                         method: 'POST',
                         body: JSON.stringify(data),
                         headers: {
                              'Content-Type': 'application/json',
                              'X-Requested-With': 'fetch'
                         }
                    });

                    //if (!response.ok)  throw new Error('Network response was not ok');

                    const result = await response.json();

                    //remove those terms from the recent and related arrays
                    if (result.code === 200) {
                         if (clearAll) {
                              recentSearches.splice(0, recentSearches.length);
                              relatedSearches.splice(0, relatedSearches.length);
                         } else {
                              recentSearches.splice(idx, 1);
                              relatedSearches.splice(idx, 1);
                         }
                         //update the dropdown
                         searchDropdown.innerHTML = await ShowSearchDropDown();
                         searchShown();
                    } else {
                         deleteError = true;
                    }
               } catch (e) {
                    deleteError = true;
                    console.error("Error deleting the recent search:", e); // Log the error
               }
          }
          function IsValidSearchTerm(searchTerm) {
               let notHtml, notEmpty = searchTerm.trim().length > 0;

               //check if the search term is a not HTML
               let doc = new DOMParser().parseFromString(searchTerm, "text/html");
               notHtml = !Array.from(doc.body.childNodes).some(node => node.nodeType === 1);

               return notHtml && notEmpty;
           }
          async function Search(searchText) {
               let data = { Term: searchText, Amount: 0 }; // search object

               if (IsValidSearchTerm(searchText)) {
                    try {
                         const response = await fetch('/Header/Search', {
                              method: 'POST',
                              body: JSON.stringify(data),
                              headers: {
                                   'Content-Type': 'application/json',
                                   'X-Requested-With': 'fetch'
                              }
                         });

                         //if (!response.ok) throw new Error('Network response was not ok');

                         const responseData = await response.json();

                         if (responseData.url !== '') {
				const st = typeof s_gi === 'function' ? s_gi(s.account) : ''; //this verifies global s tracker is available
				if (st) { // If there is a st Object, clear it out for us to send this tracking code only
        				st.clearVars();
					st.prop41 = searchText;
					st.eVar41 = searchText;
					st.linkTrackVars = "prop41,eVar41";
					st.tl(true, 'o', 'All Search');
				}
                              
                           window.location.href = responseData.url;
                         }
                    } catch (error) {
                         console.error('Search failed:', error);
                         await AddError("Error occurred during search. Please try again.");
                    }
               } else {
                    await AddError("Error, please enter a valid search term.");
               }
          }
          async function ShowSearchDropDown() {
               // show search dropdown
               if (searchDropdown.classList.contains("hide")) {
                    searchDropdown.classList.remove("hide");

                    //get the aria-hidden attribute and set it to false
                    searchDropdown.setAttribute("aria-hidden", "false");
               }
		searchOpenSmall.setAttribute("aria-expanded", "true");

               // create container and reset dropdown content
               let dropdownContent = '<div class="searchDropDown posRelative bkgdWhite animate animateFadeIn animateFaster">';

               // sanitize input
               const searchText = searchBox.value.trim().replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

               if (searchBox.value.length < minCharacters) {
                    dropdownContent += GetRecentSearchesAsync();
                    dropdownContent += GetRelatedSearchesAsync();
               } else {
                    dropdownContent += await GetSuggestionSearchesAsync(searchText);
                    dropdownContent += await GetAutoCompleteSuggestionsAsync(searchText);
               }

               //always show popular searches at the bottom
               dropdownContent += GetPopularSearchesAsync();
               dropdownContent += '</div>';
			
               return dropdownContent;
          }

          // for testing locally without API calls
          async function MockFetchRecentRelatedPopularAsync() {
               // Mock the first API call that returns a JSON object with two arrays: recentSearches and relatedSearches
               const mockFetchRecentAndRelated = Promise.resolve({
                    recentSearches: ['recent_search1', 'recent_search2', 'recent_search3'],
                    relatedSearches: ['related_search1', 'related_search2', 'related_search3']
               });

               // Mock the second API call that returns a single array of popularSearches
               const mockFetchPopularSearches = Promise.resolve(
                    [
                         {
                              "searchHits": 59193,
                              "searchTerm": "tote bags",
                              "resultCount": 2
                         },
                         {
                              "searchHits": 25795,
                              "searchTerm": "pens",
                              "resultCount": 2440
                         },
                         {
                              "searchHits": 12138,
                              "searchTerm": "blanket",
                              "resultCount": 242
                         }
                    ]
               );

               // Use Promise.all to wait for both mocked API calls to "complete"
               const results = await Promise.all([
                    mockFetchRecentAndRelated,
                    mockFetchPopularSearches
               ]);

               // Extract recentSearches and relatedSearches from the first mock fetch result,
               // and popularSearches from the second mock fetch result
               const { recentSearches, relatedSearches } = results[0];
               const popularSearches = results[1];

               // Return the combined results as an array
               return [recentSearches, relatedSearches, popularSearches];
          }
          async function MockFetchSearchSuggestions(searchText, amount = autoCompleteResultAmount) {
               const mockResponseText = JSON.stringify({
                    suggestions: [
                         { Term: 'Example suggestion 1', Amount: 10 },
                         { Term: 'Example suggestion 2', Amount: 5 },
                         { Term: 'Example suggestion 3', Amount: 2 }
                    ]
               });

               // Abort any previous requests
               suggestionAbort.abort();
               suggestionAbort = new AbortController(); // Create a new controller for the new request

               try {
                    // Simulate a network response delay
                    await new Promise(resolve => setTimeout(resolve, 100)); // Wait 100ms

                    // Since this is a mock, we're not actually sending a request, so we don't need to check response.ok
                    topSearches = mockResponseText.length ? mockResponseText : "";
                    return topSearches;

               } catch (e) {
                    if (e.name !== 'AbortError') { // Don't do anything if the "fetch" was "aborted"
                         console.error('Failed to fetch search box suggestions:', e);
                         HideLoading(searchBox);
                    }
                    return ""; // Return empty string as a fallback in case of error
               }
          }
          async function MockFetchAutoComplete(searchText, amount = autoCompleteResultAmount) {
               const mockResponseData =
                    [
                         {
                              "searchTerm": "red mug",
                              "resultCount": 423
                         },
                         {
                              "searchTerm": "red mugs",
                              "resultCount": 423
                         },
                         {
                              "searchTerm": "red mug with silver",
                              "resultCount": 109
                         },
                         {
                              "searchTerm": "black and red mug",
                              "resultCount": 343
                         },
                         {
                              "searchTerm": "red mug large",
                              "resultCount": 58
                         }
                    ];

               // Abort any previous requests
               autoCompleteAbort.abort();
               autoCompleteAbort = new AbortController(); // Create a new controller for the new request

               try {
                    // Simulate a network response delay
                    await new Promise(resolve => setTimeout(resolve, 100)); // Wait 100ms

                    return mockResponseData;

               } catch (e) {
                    if (e.name !== 'AbortError') { // Don't do anything if the fetch was aborted
                         console.error('Failed to fetch autocomplete suggestions:', e);
                         HideLoading(searchBox); // Assuming HideLoading is a function you've defined elsewhere
                    }
                    // Return an empty array or some fallback data structure in case of error
                    return [searchText, []];
               }
          }
          async function MockSearch(searchText) {
               // Simulated search object data
               let data = { Term: searchText, Amount: 0 };

               if (searchText !== '') {
                    try {
                         // Simulate a network delay and response using a Promise
                         const response = await new Promise((resolve, reject) => {
                              // Simulate a delay of 500ms
                              setTimeout(() => {
                                   // Simulate a successful response with a mock URL
                                   if (searchText === 'mocksearch') {
                                        resolve({ ok: true, json: () => Promise.resolve({ url: '/search/' + searchText }) });
                                   } else {
                                        // Simulate an error response
                                        reject(new Error('Mock error: search term not recognized'));
                                   }
                              }, 500);
                         });

                        // if (!response.ok) throw new Error('Network response was not ok');

                         const responseData = await response.json();

                         if (responseData.url !== '') {
                              console.log('Redirecting to:', responseData.url);
                              // For the mock, we'll just log the redirect URL instead of actually redirecting
                              // window.location.href = responseData.url;
                         }
                    } catch (error) {
                         console.error('Mock Search failed:', error);
                         await AddError("Error occurred during mock search. Please try again.");
                    }
               } else {
                    await AddError("Error, please enter search content to submit.");
               }
          }
     };
     return {
          Init: init,
     };
};

(function () {
     const Search4i = new search4i();
     Search4i.Init();
})();